import React from 'react';

import './iconlink.css';

function Icon() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 19.9 19.9"
    >
      <rect id="XMLID_4_" x="5.5" y="5.5" className="st0" width="3.6" height="3.6" />
      <rect id="XMLID_5_" x="10.8" y="5.5" className="st0" width="3.6" height="3.6" />
      <rect id="XMLID_8_" x="5.5" y="10.8" className="st0" width="3.6" height="3.6" />
      <rect id="XMLID_7_" x="10.8" y="10.8" className="st0" width="3.6" height="3.6" />
    </svg>
  );
}

function IconLink({ text, href }) {
  return (
    <a className="w-icon-link" href={href}>
      <Icon />
      <span>{text}</span>
    </a>
  );
}

export default IconLink;
