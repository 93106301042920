import React from 'react';
import Img from '../image';
import YoutubeVideo from '../YoutubeVideo';

function Merch({ title, subtitle, texts, images, video }) {
  return (
    <div className="ai-workpage-subsection">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="ai-workpage-section__lines-container">
              <div className="ai-workpage-section__lines" />
            </div>
          </div>
        </div>
      </div>
      <div className="ai-workpage-section__content">
        <div className="container">
          <div className="row">
            <div className="col-md-offset-1 col-md-3 col-xs-12 col-sm-8 col-sm-offset-2">
              <div>
                <div className="ai-gap--md">
                  <p className="ai-workpage-section__marketing-title">{title}</p>
                  {subtitle && (
                    <p className="ai-workpage-section__marketing-subtitle ai-text--gray">
                      {subtitle}
                    </p>
                  )}
                </div>
                <div className="ai-workpage-section__text ai-margin-top--lg ai-margin-top--md-at-sm ai-margin-top--md-at-xs">
                  {texts.map((text) => (
                    <p>{text}</p>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-xs-12 col-sm-8 col-sm-offset-2 col-md-offset-0 ai-margin-top--md-at-sm ai-margin-top--md-at-xs">
              {video ? (
                <div className="ai-gap--lg">
                  <div className="ai-workpage-section__video">
                    <YoutubeVideo title={title} url={video} />
                  </div>
                  <p className="ai-workpage-section__video-footer ai-text--gray">
                    Model for all photos and video montage: Ander Suárez
                  </p>
                </div>
              ) : (
                <div className="row ai-workpage-section__images-grid">
                  {images.map((image, index) => (
                    <div className="col-xs-12 col-sm-6">
                      {image && (
                        <Img
                          fluid={image}
                          className="ai-img-container"
                          alt={`${title} image ${index}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Merch;
