import React from 'react';

import './overlay.css';

function Overlay({ isTooltipVisible, children }) {
  return (
    <div className="ai-overlay">
      <div>{children}</div>
      <div
        className={`ai-overlay__curtain ai-overlay__curtain--clickable ${
          isTooltipVisible && 'ai-overlay__curtain--visible'
        }`}
      >
        <div>This video is Copyright © Alex Illustration. All rights reserved.</div>
      </div>
    </div>
  );
}

export default Overlay;
