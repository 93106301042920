/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
// import useOnscreen from '../../hooks/useOnscreen';

import Overlay from '../overlay/overlay';

export default ({ children, ...props }) => {
  const videoRef = useRef(null);
  const onScreen = true;
  // const onScreen = useOnscreen(videoRef, '-100px');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  useEffect(() => {
    if (onScreen) {
      videoRef.current.volume = 0.4;
    }
  }, [onScreen]);
  return (
    <Overlay isTooltipVisible={isTooltipVisible}>
      <video
        onContextMenu={(e) => {
          e.preventDefault();
          setIsTooltipVisible(true);
          setTimeout(() => {
            setIsTooltipVisible(false);
          }, 1500);
        }}
        preload={onScreen ? 'metadata' : 'none'}
        ref={videoRef}
        {...props}
      >
        {children}
      </video>
    </Overlay>
  );
};
