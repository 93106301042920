import React from 'react';
import './caption.css';

function Caption({ caption, children }) {
  if (!caption) {
    return <>{children}</>;
  }
  return (
    <div className="ai-caption-element">
      <div>{children}</div>
      <div className="ai-caption-element__caption">{caption}</div>
    </div>
  );
}

export default Caption;
