import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import projectLogo from '../../../images/monstercat/monstercatProjectLogo.svg';
import Img from '../../../components/image';
import AiVideo from '../../../components/video';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import Caption from '../../../components/caption/caption';
import IconLink from '../../../components/IconLink';
import Merch from '../../../components/merch/merch';
import BackToTop from '../../../components/back-to-top/BackToTop';

import './workpage.css';

function Works() {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          sourceInstanceName: { eq: "monstercat" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
                base64
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);
  const images = data.allFile.edges.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.node.childImageSharp.fluid.originalName]: item.node }),
    {},
  );
  return (
    <Layout>
      <SEO title="Monstercat" />
      <div className="ai-workpage">
        <div className="ai-workpage-main-section">
          <h1>Uncaged Vol. X</h1>
          <div className="ai-workpage-links">
            <IconLink text="Project Logo" href="#logo" />
            <IconLink text="Cat Head" href="#cathead" />
            <IconLink text="Merchandising" href="#merchandising" />
          </div>
        </div>
        <div className="ai-gap--lg">
          <div className="ai-workpage-section ai-gap--lg" id="logo">
            <h2 className="ai-workpage-section__header">
              <span className="ai-workpage-section__header-container">Project Logo</span>
            </h2>
            <div className="ai-workpage-section__content">
              <div className="container ai-gap--lg">
                <div className="row">
                  <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="ai-workpage-section__maincontent">
                      <img src={projectLogo} alt="The X" />
                      <div className="ai-workpage-section__text ai-workpage-section__text--white">
                        <p>
                          Since the very first moment of the project, I decided to build all around
                          the X, as I wanted to make an Uncaged Vol. X, and the overall aesthetic of
                          the project was going to be built around mechas.
                        </p>
                        <p>
                          So to wrap up everything that&#39;s in the project, I created a logo for
                          it.
                        </p>
                        <p>This logo is based on two things:</p>
                        <ul>
                          <li>The new upcoming logo from Monstercat.</li>
                          <li>The diagonal lines that are usually used on mecha drawings.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <Img
                      fluid={images['logo1.jpg'].childImageSharp.fluid}
                      className="ai-img-container"
                      alt="logo image"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <Img
                      fluid={images['logo2.jpg'].childImageSharp.fluid}
                      className="ai-img-container"
                      alt="logo image"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <Img
                      fluid={images['logo3.jpg'].childImageSharp.fluid}
                      className="ai-img-container"
                      alt="logo image"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <Img
                      fluid={images['logo4.jpg'].childImageSharp.fluid}
                      className="ai-img-container"
                      alt="logo image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ai-workpage-section ai-gap--lg" id="cathead">
            <div>
              <h2 className="ai-workpage-section__header">
                <span className="ai-workpage-section__header-container">Cat Head</span>
              </h2>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="ai-workpage-section__lines-container">
                      <div className="ai-workpage-section__lines ai-workpage-section__lines__cathead" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ai-workpage-section__content">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-md-offset-1 col-xs-12 col-sm-8 col-sm-offset-2">
                    <div className="ai-workpage-section__text ai-workpage-section__text--centered">
                      <p>
                        For my Cat Head I wanted to stick to the label&#39;s previous designs (made
                        by Amanda Cha), but with my own style.
                      </p>
                      <p>The three things that define my style are:</p>
                      <ul>
                        <li>Sharp strokes</li>
                        <li>Mecha inspired designs</li>
                        <li>A mix of plain colors and textures</li>
                      </ul>
                      <p>
                        So I designed a very aggressive Cat Head with a mecha theme placed in a
                        mysterious background where I played with textures.
                      </p>
                    </div>
                  </div>
                  <div className="ai-margin-top--md-at-xs ai-margin-top--md-at-sm col-md-5 col-xs-12 col-sm-8 col-sm-offset-2 col-md-offset-0">
                    <Caption caption="Artwork">
                      <Img
                        fluid={images['Monstercat.jpg'].childImageSharp.fluid}
                        className="ai-img-container"
                        alt="Artwork"
                      />
                    </Caption>
                  </div>
                </div>
                <div className="row ai-margin-top--lg ai-margin-top--md-at-xs ai-margin-top--md-at-sm">
                  <div className="col-md-offset-0 col-md-12 col-xs-12 col-sm-8 col-sm-offset-2">
                    <div className="ai-right-aligned-flexible-elements">
                      <Caption caption="IG Stories AD - Mobile Wallpaper">
                        <div className="ai-right-aligned-flexible-elements__element">
                          <Img
                            fluid={images['staticstory.jpg'].childImageSharp.fluid}
                            className="ai-img-container"
                            alt="Instagram story"
                          />
                        </div>
                      </Caption>
                      <Caption caption="IG Stories AD - Animation">
                        <div className="ai-right-aligned-flexible-elements__element">
                          <AiVideo disablePictureInPicture controlsList="nodownload" controls>
                            <source
                              src="https://alexillustration.s3.eu-west-2.amazonaws.com/monstercat.mp4"
                              type="video/mp4"
                            />
                          </AiVideo>
                        </div>
                      </Caption>
                      <Caption caption="IG Stories AD - Sound Design">
                        <div className="ai-right-aligned-flexible-elements__element ai-right-aligned-flexible-elements__element--expanded">
                          <Img
                            fluid={images['flpscreen.png'].childImageSharp.fluid}
                            className="ai-img-container"
                            alt=""
                          />
                        </div>
                      </Caption>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ai-workpage-section ai-gap--lg" id="merchandising">
            <h2 className="ai-workpage-section__header">
              <span className="ai-workpage-section__header-container">Merchandising</span>
            </h2>
            {/* cathead 1 */}
            <div className="ai-gap--xl">
              <Merch
                title="Cat Head"
                subtitle="T-shirt"
                texts={[
                  `As Monstecat does, here's the usual T-Shirt with the Uncaged album Cat Head.`,
                  `Every T-Shirt and the Hoodie has the X logo on it's back.`,
                ]}
                images={[
                  images['cathead1.jpg'].childImageSharp.fluid,
                  images['cathead2.jpg'].childImageSharp.fluid,
                  images['cathead3.jpg'].childImageSharp.fluid,
                  images['cathead4.jpg'].childImageSharp.fluid,
                ]}
              />
              {/* monstechcat tshirt 2 */}
              <Merch
                title={
                  <>
                    MONS
                    <br />
                    TECH
                    <br />
                    CAT
                  </>
                }
                subtitle="T-shirt"
                texts={[
                  `Monstercat is turning the overall aesthetic into something more into the graphic design way.`,
                  `As I really like making graphic design things, I decided to create a cool composition just playing
              with the project’s name “MONSTECHCAT”.`,
                ]}
                images={[
                  images['2tshirt1.jpg'].childImageSharp.fluid,
                  images['2tshirt2.jpg'].childImageSharp.fluid,
                  null,
                  images['2tshirt3.jpg'].childImageSharp.fluid,
                ]}
              />
              {/* Hoodie */}
              <Merch
                title="Hoodie X"
                texts={[
                  `For the Hoodie design I made a bunch of different options, but this one absolutely turned out
              the best, not just visually but on its concept.`,
                  `The concept on the arms is a simplified barbed wire, to wink the other half of the project,
              RIOT. And of course, related to the X.`,
                  `And the chest design is a mix of the simplified barbed wire and the project’s Logo.`,
                ]}
                images={[
                  images['hoodie1.jpg'].childImageSharp.fluid,
                  images['hoodie2.jpg'].childImageSharp.fluid,
                  images['hoodie3.jpg'].childImageSharp.fluid,
                  images['hoodie4.jpg'].childImageSharp.fluid,
                ]}
              />
              {/* Mask */}
              <Merch
                title="Mask"
                texts={[
                  `I pretty much always add these two kind of rhombus to every illustration I make, so the idea of
              placing it into a Mask has been always in my head, as I think it really fits on it.`,
                  `Also, it’s a wink to my Cat Head design, as it has it on his mouth as well!`,
                ]}
                images={[
                  images['mask1.jpg'].childImageSharp.fluid,
                  images['mask2.jpg'].childImageSharp.fluid,
                ]}
              />
              {/* Video montage */}
              <Merch
                title="Video montage"
                texts={[`And of course, as the label does, here’s my Video Montage for my brand!`]}
                video="https://www.youtube.com/embed/gPZ7WyJg1wc"
                images={[]}
              />
              {/* Logo */}
              <Merch
                title="Logo"
                subtitle="Stickers"
                texts={[
                  `Some stickers I made with the project Logo to place on different things of my project: box,
              sketchbook, notebook…`,
                  `Printed in two colors, Black & Blue, so there’s no problem for where you want to put them on.`,
                ]}
                images={[
                  images['logostickers1.jpg'].childImageSharp.fluid,
                  images['logostickers2.jpg'].childImageSharp.fluid,
                ]}
              />
              {/* Cathead stickers */}
              <Merch
                title="Cat Head"
                subtitle="Stickers"
                texts={[
                  `As the label, here you have my Cat Head stickers.`,
                  `Also it should be pointed out that the stickers have a sharp and aggressive cut, what the design
              deserves.`,
                ]}
                images={[images['catheadstickers.jpg'].childImageSharp.fluid]}
              />
            </div>
          </div>
        </div>
        <BackToTop />
      </div>
    </Layout>
  );
}

export default Works;
