import React, { useRef, useState, useEffect } from 'react';
import useOnscreen from '../../hooks/useOnscreen';

export default ({ title, url }) => {
  const ref = useRef();
  const onScreen = useOnscreen(ref, '-100px');
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    if (onScreen) {
      setVideoUrl(url);
    }
  }, [onScreen]);
  return (
    <iframe
      ref={ref}
      title={`${title} video`}
      src={videoUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};
